.jumbotron.issue {
  text-align: justify;
}

.well p:last-child {
  margin: 0 0 0 0;
}

.well hr {
  margin: 10px 0;
}

.well .label {
  font-size: 12px;
  margin-right: 10px;
}

.comment-submit {
  margin: 10px 0 30px 0;
  width: 100%;
}

.btn-no-active .btn:hover {
    background: inherit;
    border-color: #ccc !important;
    cursor: default !important;
}

.btn-no-active .btn:active, .btn-no-active .btn:focus {
    box-shadow: none !important;
    background-color: inherit;
    border-color: #ccc !important;
}
